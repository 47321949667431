import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from 'react-router-dom'
import { isEmpty } from 'lodash';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import moment from 'moment';
import { useProfile } from '../hooks';
import InputMask from 'react-input-mask';

import { logout } from "../actions/api-auth";
import { countryList, stateList, cityList, profileData, profileUpdate } from "../actions/api-home";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Compressor from 'compressorjs';

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
};
  
const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
};
  
const vmobile = (value) => {
    if (value.length < 9 || value.length > 12 || isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
    if(isNaN(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter Valid Mobile number
        </div>
      );
    }
};

const vnumber = (value) => {
  if(isNaN(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter Valid Number
      </div>
    );
  }
};

const Profile = (props) => {
    const { isLoggedIn } = useSelector(state => state.authReducer);
    const form = useRef();
    const checkBtn = useRef();
    const {
      profile: profile,
    } = useProfile();

    const [successful, setSuccessful] = useState(false);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [salut, setSalut] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [dialcode, setDialCode] = useState('');
    const [fullmobile, setFullMobile] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState(new Date());
    const [marital, setMarital] = useState('Married');
    const [countryid, setCountryId] = useState('');
    const [stateid, setStateId] = useState('');
    const [cityid, setCityId] = useState('');
    const [street, setStreet] = useState('');
    const [picture, setPicture] = useState('');
    const [pincode, setPincode] = useState('');
    const [education, setEducation] = useState('');
    const [profession, setProfession] = useState('');
    const [professiondetail, setProfessionDetail] = useState('');
    const [company, setCompany] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [knowmatangi, setKnowMatangi] = useState('');
    const [friendname, setFriendName] = useState('');
    const [friendmobile, setFriendMobile] = useState('');
    const [knowother, setKnowOther] = useState('');
    

    const { message, msg_type } = useSelector(state => state.messageReducer);
    const profilestate = useSelector(state => state),
    { homeReducer } = profilestate,
    { country_lists, state_lists, city_lists, profile_detail } = homeReducer;

    const dispatch = useDispatch();

    useEffect(() => {
          document.title = "Profile | Matangi";
          dispatch(countryList());
          dispatch(stateList(profile.country_id));
          dispatch(cityList(profile.state_id));
          setSalut(profile.salut);
          setName(profile.name);
          setEmail(profile.email);
          setMobile(profile.mobile);
          setDialCode(profile.dial_code);
          setFullMobile('+'+profile.dial_code+profile.mobile);
          setGender(profile.gender);
          setMarital(profile.marital_status);
          setCountryId(profile.country_id);
          setStateId(profile.state_id);
          setCityId(profile.city_id);
          setStreet(profile.street_address);
          setPincode(profile.pincode);
          setEducation(profile.education);
          setProfession(profile.profession);
          setProfessionDetail(profile.profession_detail);
          setWhatsapp(profile.whatsapp_number);
          setCompany(profile.company_name);
          setKnowMatangi(profile.know_matangi);
          setFriendName(profile.friend_name);
          setFriendMobile(profile.friend_mobile);
          setKnowOther(profile.know_other);
          if(profile.dob){
            setDob(new Date(Date.parse(profile.dob)));
          }
    }, [dispatch, profile]);

    useEffect(() => {
      if(!isEmpty(country_lists.data)) {
        const countries = [];
        const docs =country_lists.data;
        docs.forEach((snap) => {
            if(snap != null){
              const data=  {'name':snap.name,'value':snap.id};
              countries.push(data);
            }
        });
        setCountry(countries);
      }
      if(!isEmpty(state_lists.data)) {
        const states = [];
        const docs =state_lists.data;
        docs.forEach((snap) => {
          if(snap != null){
            const data=  {'name':snap.name,'value':snap.id};
            states.push(data);
          }
        });
			  setState(states);
      }
      if (!isEmpty(city_lists.data)) {
        const cities = [];
        const docs =city_lists.data;
        docs.forEach((snap) => {
          if(snap != null){
            const data=  {'name':snap.name,'value':snap.id};
            cities.push(data);
          }
        });
			  setCity(cities);
      }
    }, [country_lists, state_lists, city_lists]);

    const  handleSalutChange = (event) => {
      setSalut(event.target.value);
    }
    const  handleNameChange = (event) => {
      setName(event.target.value);
    }
    const  handleEmailChange = (event) => {
      setEmail(event.target.value);
    }
    // const  handleMobileChange = (event) => {
    //   setMobile(event.target.value);
    // }

    const handleMobileChange = (status, phoneNumber, country) => {
      var ret = phoneNumber.replace('+'+country.dialCode,'');
      setMobile(ret);
      setDialCode(country.dialCode);
      setFullMobile(ret);
    };

    const  handleGenderChange = (event) => {
      setGender(event.target.value);
    }
    const  handleMaritalChange = (event) => {
      setMarital(event.target.value);
    }
    const  handleStreetChange = (event) => {
      setStreet(event.target.value);
    }
    const  handlePincodeChange = (event) => {
      setPincode(event.target.value);
    }
    const  handleEducationChange = (event) => {
      setEducation(event.target.value);
    }
    const  handleProfessionChange = (event) => {
      setProfession(event.target.value);
    }
    const  handleProfessionDetailChange = (event) => {
      setProfessionDetail(event.target.value);
    }
    const  handleCompanyChange = (event) => {
      setCompany(event.target.value);
    }
    const  handleWhatsappChange = (event) => {
      setWhatsapp(event.target.value);
    }
    const  handleCountryChange = (optionSelected) => {
      dispatch(stateList(optionSelected));
      setCountryId(optionSelected);
    }
    const  handleStateChange = (optionSelected) => {
      dispatch(cityList(optionSelected));
      setStateId(optionSelected);
    }
    const  handleCityChange = (optionSelected) => {
      setCityId(optionSelected);
    }

    const  handlePictureChange = (event) => {
      const image = event.target.files[0];
      new Compressor(image, {
        quality: 0.8,
        maxWidth:200,
        maxHeight:200,
        success: (compressedResult) => {
          setPicture(compressedResult);
        },
      });
    }

    const  handleKnowMatangi = (event) => {
      setKnowMatangi(event.target.value);
    }
    const  handleFriendName = (event) => {
      setFriendName(event.target.value);
    }
    const  handleFriendMobile = (event) => {
      setFriendMobile(event.target.value);
    }
    const  handleKnowOther = (event) => {
      setKnowOther(event.target.value);
    }

    const updateDob = (date) => {
        setDob(date);
    };

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();
          var ret = mobile.replace('+'+dialcode,'');
          if(dob !=''){
            var dobdata = moment(dob).format('YYYY-MM-DD');
          }else{
            var dobdata = new Date();
          }
          const data = {'salut':salut,'name':name,'email':email,'mobile':ret,'dial_code':dialcode,'dob':dobdata,'gender':gender,'marital_status':marital,'country_id':countryid,'state_id':stateid,'city_id':cityid,'picture':picture,'street_address':street,'pincode':pincode,'profession':profession,'profession_detail':professiondetail,'company_name':company,'whatsapp_number':whatsapp, 'education':education, 'know_matangi':knowmatangi, 'friend_name':friendname, 'friend_mobile':friendmobile, 'know_other':knowother}

          let formData = new FormData();
          for(let dataitem in data){
            formData.append(dataitem, data[dataitem]);
          }

          dispatch(profileUpdate(formData))
            .then(() => {
              localStorage.setItem('profile_status', 0);
              setSuccessful(true);
              window.location.reload();
            })
            .catch(() => {
            setSuccessful(false);
            });
        
    };


    if(!isLoggedIn) {
      localStorage.setItem("navtype", 1);
      return <Redirect to="/login" />;
    }
    
    const feedcheck = localStorage.getItem("reg_id");
    if(feedcheck !=0){
      const program_id = localStorage.getItem("regprogram_id");
      if(program_id ==1){
        props.history.push("/ulchemy-feedback");
        window.location.reload();
      }
      if(program_id ==2){
        props.history.push("/ulchemyelite-feedback");
        window.location.reload();
      }
      if(program_id ==3){
        props.history.push("/divija-feedback");
        window.location.reload();
      }
      if(program_id ==4){
        props.history.push("/kaivalya-feedback");
        window.location.reload();
      }
      if(program_id ==5){
        props.history.push("/vision-feedback");
        window.location.reload();
      }
    }

    const logOut = (e) => {
      dispatch(logout());
    };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action active"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
              {/* <div><h3 style={{color:'#2bbbb5',fontWeight:'bold'}}>Welcome, {salut}{name}</h3><br/></div> */}
              <div className="profile-head">
							  <h3>Profile Detail</h3>
              </div>
							<p></p>
              { profile.picture &&
                <img src={ profile.picture } alt="" className="img-responsive" />
              }
							<Form onSubmit={handleProfileUpdate} ref={form}>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group" style={{width:'16%',display:'inline-block'}}>
                          <label> Name <sup>*</sup></label>
                          <Select name="salut" className="form-control" value={ salut } onChange={handleSalutChange} style={{paddingLeft:'0px',paddingRight:'1px'}}>
                            <option value="">Select</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Mrs.">Mrs.</option>
                          </Select>
                      </div>
                      <div className="form-group" style={{width:'84%',display:'inline-block'}}>
                          <Input type="text" className="form-control" name="name" value={ name } onChange={handleNameChange} validations={[required]} placeholder="Enter Name..."  />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                          <label> Email address <sup>*</sup></label>
                          <Input type="text" className="form-control" name="email" value={ email } onChange={handleEmailChange}  validations={[required, validEmail]} placeholder="Enter Email ID..." />
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                          <label> Mobile Number <sup>*</sup></label><br/>
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            separateDialCode={true}
                            autoHideDialCode={true}
                            defaultCountry="in"
                            value={fullmobile}
                            allowDropdown={true}
                            onPhoneNumberChange={handleMobileChange}
                            telInputProps={[required, vmobile]}
                          />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                          <label> Date of Birth <sup>*</sup></label>
                          
                      
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Date of Birth"
                          value={dob}
                          disableFuture={true}
                          inputFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          onChange={(newValue) => {
                            updateDob(newValue);
                          }}
                          autoOk={true}
                          popperPlacement="bottom"
                          renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <InputMask className="form-control" ref={inputRef} {...inputProps} placeholder="__/__/____" mask="99/99/9999" maskChar="_" />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Gender <sup>*</sup></label>
                        <Select name="gender" className="form-control" value={ gender } onChange={handleGenderChange}>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="transgender">Transgender</option>
                        </Select>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Marital status <sup>*</sup></label>
                        <Select name='marital_status' className="form-control" value={ marital } onChange={handleMaritalChange}>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                          <option value="Divorced">Divorced</option>
                        </Select>
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Select Country <sup>*</sup></label>
                        <SelectSearch options={country} value={ countryid } onChange={handleCountryChange} search filterOptions={fuzzySearch} name="country_id" emptyMessage="Not found"  placeholder="Choose your country" validations={[required]}/>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Select State <sup>*</sup></label>
                        <SelectSearch options={state} value={ stateid } onChange={handleStateChange} search filterOptions={fuzzySearch} name="state_id" emptyMessage="Not found"  placeholder="Choose your State" required/>
                      </div>
                  </div>  
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Select City <sup>*</sup></label>
                        <SelectSearch options={city} value={ cityid } onChange={handleCityChange} search filterOptions={fuzzySearch} name="city_id" emptyMessage="Not found"  placeholder="Choose your City" required/>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Address <sup>*</sup></label>
                        <Input type="text" className="form-control" name="street_address" value={ street } onChange={handleStreetChange} validations={[required]} placeholder="Enter Address..." />
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Pincode <sup>*</sup></label>
                        <Input type="text" className="form-control" name="pincode" value={ pincode } onChange={handlePincodeChange} validations={[required, vnumber]} placeholder="Enter Pincode..." />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Education</label>
                        <Input type="text" className="form-control" name="education" value={education} onChange={handleEducationChange} placeholder="Enter Education..." />
                      </div>
                      
                  </div> 
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                        <label> Profession <sup>*</sup></label>
                        <Select name='profession' className="form-control" value={ profession } onChange={handleProfessionChange} validations={[required]}>
                          <option value="">Select Profession</option>
                          <option value="Artist">Artist</option>
                          <option value="Business">Business</option>
                          <option value="Chartered Accountant">Chartered Accountant</option>
                          <option value="Civil Service">Civil Service</option>
                          <option value="Designer">Designer</option>
                          <option value="Engineer">Engineer</option>
                          <option value="Entertainment Industry">Entertainment Industry</option>
                          <option value="Fashion Industry">Fashion Industry</option>
                          <option value="Govt employee">Govt employee</option>
                          <option value="Homemaker">Homemaker</option>
                          <option value="Hotel Management">Hotel Management</option>
                          <option value="IT professional">IT professional</option>
                          <option value="Legal profession">Legal profession</option>
                          <option value="Makeup artists">Makeup artists</option>
                          <option value="Management Consultant">Management Consultant</option>
                          <option value="Management Professional">Management Professional</option>
                          <option value="Medical Industry">Medical Industry</option>
                          <option value="Media Industry">Media Industry</option>
                          <option value="Merchant Navy">Merchant Navy</option>
                          <option value="NGO/ Charity Careers">NGO/ Charity Careers</option>
                          <option value="Private sector employee">Private sector employee</option>
                          <option value="Scientist">Scientist</option>
                          <option value="Student">Student</option>
                          <option value="Teacher">Teacher</option>
                          <option value="Technology Careers">Technology Careers</option>
                          <option value="Other">Other</option>
                        </Select>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                        <label> Profession Detail <sup>*</sup></label>
                        <Input type="text" className="form-control" name="profession_detail" value={ professiondetail } onChange={handleProfessionDetailChange} placeholder="Enter Profession Detail..." />
                      </div>
                  </div>  
                </div> 

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Company Name</label>
                      <Input type="text" className="form-control" name="company_name" value={ company } onChange={handleCompanyChange} placeholder="Enter Company Name..." />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Whatsapp number</label>
                      <Input type="text" className="form-control" name="whatsapp_number" value={whatsapp} onChange={handleWhatsappChange} placeholder="Enter Whatsapp Number..." />
                    </div>
                  </div>    
                </div>
                <div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Profile Picture</label>
                      <Input type="file" className="form-control" name="picture" onChange={handlePictureChange} placeholder="Select Profile Picture..." />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> How did you know about the program?<sup>*</sup></label>
                      <Select name='know_matangi' className="form-control" value={ knowmatangi } onChange={handleKnowMatangi} validations={[required]}>
                        <option value="">Select Option</option>
                        <option value="Facebook">Facebook</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Friend">Friend</option>
                        <option value="Other">Other</option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={knowmatangi =="Friend" ? "col-md-6 d-block" : "col-md-6 d-none"}>
                      <div className="form-group">
                        <label> Friend Name</label>
                        <Input type="text" className="form-control" name="friend_name" value={friendname} onChange={handleFriendName} placeholder="Enter Friend Name..." />
                      </div>
                    </div>
                    <div className={knowmatangi =="Friend" ? "col-md-6 d-block" : "col-md-6 d-none"}>
                      <div className="form-group">
                        <label> Friend Mobile Number</label>
                        <Input type="text" className="form-control" name="friend_mobile" value={friendmobile} onChange={handleFriendMobile} placeholder="Enter Friend Mobile Number..." validations={[vnumber]}/>
                      </div>
                    </div>
                    <div className={knowmatangi =="Other" ? "col-md-6 d-block" : "col-md-6 d-none"}>
                      <div className="form-group">
                        <label> If others, Describe</label>
                        <Input type="text" className="form-control" name="know_other" value={knowother} onChange={handleKnowOther} placeholder="If others, Describe..." />
                      </div>
                    </div>
                  </div>
                </div>
                
                <button className="btn btn-primary loginbtn">Submit</button>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
                            
              {message && (
                <div className="form-group">
                <div className={"alert "+msg_type} role="alert">
                  {message}
                </div>
                </div>
              )}
						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default Profile;
