import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash';
import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { logout } from "../actions/api-auth";
import { registerDetail } from "../actions/api-home";
import { advancePay } from "../actions/api-wisdom";

import { config } from '../actions/config.js';
import authHeader from "../actions/auth-header";
import axios from 'axios';
const BASEURL = config.BASEURL;

const EventDetail = (props) => {
  const { id } = useParams();
  const [custommsg, setCustommsg] = useState();
  const [htmlpdf, setHtmlpdf] = useState();
  const [successful, setSuccessful] = useState(false);
  const { isLoggedIn } = useSelector(state => state.authReducer);
  const [eventdetail, setEventDetail] = useState([]);
  const eventdetailstate = useSelector(state => state),
  { homeReducer } = eventdetailstate,
  { register_detail } = homeReducer;

  const eventstate = useSelector(state => state),
    { wisdomReducer } = eventstate,
    { payment_data } = wisdomReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Profile | Event Detail";
		dispatch(registerDetail(id));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(register_detail)) {
			setEventDetail(register_detail);
		}
	}, [register_detail, payment_data]);

  if(!isLoggedIn) {
    localStorage.setItem("navtype", 1);
    return <Redirect to="/login" />;
  }

  const logOut = (e) => {
    dispatch(logout());
  };

  const handleRegister = (e) => {
    e.preventDefault();
    var id = e.currentTarget.getAttribute("data-id")
    var profileinfo = e.currentTarget.getAttribute("data-profileinfo");
    var program_id = e.currentTarget.getAttribute("data-programid");
    var event_id =e.currentTarget.getAttribute("data-eventid");
    if(profileinfo ==1){
      setSuccessful(false); 
      dispatch(advancePay(id))
        .then((res) => {
            if(res.success ==1){
                setSuccessful(true);
                handlePayment(res.data);
            }else if(res.success ==2){
                setSuccessful(true);
            }else{
                setSuccessful(false);
                setCustommsg(res.message);
            }
        })
        .catch(() => {
            setSuccessful(false);
        });
    }else{
      console.log("hai");
      localStorage.setItem("navtype", program_id);
      localStorage.setItem("event_id", event_id);
      if(program_id =='1'){
        props.history.push("/ulchemy");
        window.location.reload();
      }
      if(program_id =='2'){
        props.history.push("/ulchemy-elite");
        window.location.reload();
      }
      if(program_id =='3'){
        props.history.push("/divija");
        window.location.reload();
      }
      if(program_id =='4'){
        props.history.push("/kaivalya");
        window.location.reload();
      }
      if(program_id =='5'){
        props.history.push("/vision");
        window.location.reload();
      }
      if(program_id =='6'){
        props.history.push("/shivratri");
        window.location.reload();
      }
      if(program_id =='7'){
        props.history.push("/karmayogam");
        window.location.reload();
      }
      if(program_id =='8'){
        props.history.push("/paradesham");
        window.location.reload();
      }
    }
  };

  const handlePayment = (data) => {
    var reqJson = {
      features: {
        enableAbortResponse: true,
        enableExpressPay: true,
        enableInstrumentDeRegistration: true,
        enableMerTxnDetails: true,
        enableNewWindowFlow: true,
      },
      consumerData: {
        deviceId: data.deviceId,
        token: data.hash,
        responseHandler: handleResponse,
        paymentMode: "all",
        merchantLogoUrl: "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",
        merchantId: data.marchantId,
        currency: data.currencycode,
        consumerId: data.consumerId,
        consumerMobileNo: data.consumerMobileNo,
        consumerEmailId: data.consumerEmailId,
        cartDescription: data.consumerName,
        txnId: data.txnId,
        items: [{
              itemId: data.schemecode,
              amount: data.amount,
              comAmt: "0",
        }],
        customStyle: {
          PRIMARY_COLOR_CODE: "#45beaa",
          SECONDARY_COLOR_CODE: "#FFFFFF",
          BUTTON_COLOR_CODE_1: "#2d8c8c",
          BUTTON_COLOR_CODE_2: "#FFFFFF",
        },
      },
    };
    window.$.pnCheckout(reqJson);
  };

  const handleResponse = async(res) => {
      setSuccessful(false);
      const data = {'merchantCode' : res.merchantCode, 'token':res.paymentMethod.paymentTransaction.identifier};
      const response = await axios.post(BASEURL + 'paymentresponse', data, { headers: authHeader() });
      dispatch(registerDetail(id));
  };

  const downloadpdf = async() => {
    const data = {'invoice_id' : id, 'invoice_type' : 'reg'};
    const res = await axios.post(BASEURL + 'register/pdf', data, { headers: authHeader() });
    if(res.data){
      setHtmlpdf(res.data);
      const input = document.getElementById('pagepdf');
      html2canvas(input,{scale: 2})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('receipt.pdf');
        setHtmlpdf('');
      });
    }
  };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
                <div className="profile-head">
                  <h3>Event Detail {eventdetail.pay_status =='success' && ( <button className="btn btn-sm history-btn btn-warning" onClick={downloadpdf}>Download Invoice</button>)}

                  {/* <h3>Event Detail */}

                  { eventdetail.event_status =='notstarted' &&
                    <button className="btn btn-sm history-btn btn-success">Not Started</button>
                  }
                  { eventdetail.event_status =='ongoing' &&
                    <button className="btn btn-sm history-btn btn-success">On Going</button>
                  }
                  { eventdetail.event_status =='completed' &&
                    <button className="btn btn-sm history-btn btn-success">Completed</button>
                  }
                  { eventdetail.event_status =='cancelled' &&
                    <button className="btn btn-sm history-btn btn-success">Cancelled</button>
                  }
                  </h3> 
                </div>
                <div className="detailblock">
                {(eventdetail.pay_status =='success' || eventdetail.pay_status =='partialpay' || eventdetail.pay_status =='paylater') && (
                <p>Reg ID : <span className="float-right">{eventdetail.regevent_id}</span></p>)}
                {/* <p>Unique ID : <span className="float-right">REG{eventdetail.id}</span></p> */}
                <p>Event Name : <span className="float-right">{eventdetail.event_name}</span></p>
                <p>Participant Name : <span className="float-right">{eventdetail.participant_name}</span></p>
                <p>Event Date : <span className="float-right">{eventdetail.event_date}</span></p>
                <p>Event Address : <span className="float-right">{eventdetail.event_address}</span></p>
                <p>Event Price : <span className="float-right">{eventdetail.currency} {eventdetail.amount}</span></p>
                {eventdetail.discount !=0 && (
                    <p>Second Time Discount ({ eventdetail.discount }%) : <span className="float-right">- {eventdetail.currency} { eventdetail.discount_value } </span></p>
                )}
                {eventdetail.early_value !=0 && (
                    <p>Early Bird Discount : <span className="float-right">- {eventdetail.currency} { eventdetail.early_value } </span></p>
                )}
                <p>Total : <span className="float-right">{eventdetail.currency} {eventdetail.total}</span></p>
                <p>Advance : <span className="float-right">{eventdetail.currency} {eventdetail.advance_amount}</span></p>
                <p>Payable : <span className="float-right">{eventdetail.currency} {eventdetail.payable}</span></p>
                <p>Payment Status : <span className="float-right">{eventdetail.pay_status}</span></p>
                {eventdetail.payment_id && (
                    <p>Payment Reference ID : <span className="float-right"> { eventdetail.payment_id } </span></p>
                )}
                {(eventdetail.pay_status =='partialpay' || eventdetail.pay_status =='paylater') && (
                  <div>
                    {eventdetail.event_close ==0 ? (
                    <button className="btn btn-primary loginbtn" onClick={handleRegister} data-id={eventdetail.id} data-profileinfo={eventdetail.profile_info} data-eventid={eventdetail.event_id} data-programid={eventdetail.program_id}> Pay Now</button>)
                    :(
                      <button className="btn btn-danger">Please Contact Below Mentioned Number</button>
                    )}
                  </div>
                )}
                <hr></hr>
                <h4 style={{"color":"#2e3191"}}><b>Contact Info</b></h4>
                  <p><b>Name:</b> {eventdetail.ref_name}, <br /><b>Email:</b> {eventdetail.ref_email}, <br /><b>Contact Number:</b> {eventdetail.ref_phone}</p>
                </div>
                

                {(successful) &&(
                  <div className="login-right">
                  <h3>Registered Successfully</h3>
                  <p><Link to={"/profile"}>Click here to View Info</Link></p>
                  </div>
                )}

                <div id="pagepdf" dangerouslySetInnerHTML={{__html: htmlpdf }}></div>
						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default EventDetail;
