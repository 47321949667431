import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { logout } from "../actions/api-auth";
import { historyList } from "../actions/api-home";

const HistoryEvent = (props) => {
  const { isLoggedIn } = useSelector(state => state.authReducer);
  const [historylist, setHistoryList] = useState([]);
  const historystate = useSelector(state => state),
  { homeReducer } = historystate,
  { history_lists } = homeReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Profile | History Event";
		dispatch(historyList());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(history_lists.data)) {
			setHistoryList(history_lists.data);
		}
	}, [history_lists]);

  if(!isLoggedIn) {
    localStorage.setItem("navtype", 1);
    return <Redirect to="/login" />;
  }
  const userflag = localStorage.getItem("profile_status");
  if(userflag ==1){
    alert("Please update Your Profile Details");
    props.history.push("/profile");
    window.location.reload();
  }

  const updateLocal = (event) => {
    const value = event.currentTarget.getAttribute("data-value");
    localStorage.setItem("reg_id", value);
  };

  const feedcheck = localStorage.getItem("reg_id");
  if(feedcheck !=0){
    const program_id = localStorage.getItem("regprogram_id");
    if(program_id ==1){
      props.history.push("/ulchemy-feedback");
      window.location.reload();
    }
    if(program_id ==2){
      props.history.push("/ulchemyelite-feedback");
      window.location.reload();
    }
    if(program_id ==3){
      props.history.push("/divija-feedback");
      window.location.reload();
    }
    if(program_id ==4){
      props.history.push("/kaivalya-feedback");
      window.location.reload();
    }
    if(program_id ==5){
      props.history.push("/vision-feedback");
      window.location.reload();
    }
  }
  
  const logOut = (e) => {
    dispatch(logout());
  };

    return (
    <section className="section profile-sec">
			<div className="container-fluid">
				<div className="row">
          <div className="col-md-3">
						<div className="profile-left">
              <div className="list-group list-group-flush">
                  <Link to="/profile" className="list-group-item list-group-item-action"><span>Profile <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/current-event" className="list-group-item list-group-item-action"><span>Current Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/history-event" className="list-group-item list-group-item-action active"><span>History Events <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/donation-list" className="list-group-item list-group-item-action"><span>Donation List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/favourite-list" className="list-group-item list-group-item-action"><span>Favourite List <i className="fa fa-chevron-right"></i></span></Link>
                  <Link to="/login" onClick={logOut} className="list-group-item list-group-item-action"><span>Logout <i className="fa fa-chevron-right"></i></span></Link>
              </div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="profile-right">
              <div className="profile-head">
							  <h3>History Event</h3>
              </div>
							<p></p>

              <table className="table table-responsive history-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Event Name</th>
                    <th>Participant Name</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Event Status</th>
                    <th>Price</th>
                    <th>Payment Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    { historylist.length > 0 ? historylist.map((historydata, pindex) => (
                    <tr>
                      <td>{pindex +1}</td>
                      <td>{historydata.event_name}</td>
                      <td>{historydata.participant_name}</td>
                      <td>{historydata.event_date}</td>
                      <td>{historydata.event_address}</td>
                      { historydata.event_status =='notstarted' &&
                         <td>Not Started</td>
                      }
                      { historydata.event_status =='ongoing' &&
                         <td>On Going</td>
                      }
                      { historydata.event_status =='completed' &&
                         <td>Completed</td>
                      }
                      { historydata.event_status =='cancelled' &&
                         <td>Cancelled</td>
                      }
                      <td>{historydata.currency} {historydata.total}</td>
                      <td>
                        {historydata.pay_status =='success' && (<button className="btn btn-sm history-btn btn-success">Success</button>)}
                        {historydata.pay_status =='pending' && (<button className="btn btn-sm history-btn btn-info">Pending</button> )}
                        {historydata.pay_status =='failed' && (<button className="btn btn-sm history-btn btn-danger">Failed</button> )}
                        {historydata.pay_status =='partialpay' && (<button className="btn btn-sm history-btn btn-success">Partial Pay</button> )}
                        {historydata.pay_status =='paylater' && (<button className="btn btn-sm history-btn btn-warning">Pay Later</button> )}
                      </td>
                      <td><Link className="btn btn-sm history-btn btn-primary" to={"/eventdetail/" + historydata.id}>View</Link>
                          {historydata.pay_status =='success' && historydata.event_status =='completed' &&  historydata.feedback ===0 ? 
                            <div style={{'display':'inline-block'}}>
                              { historydata.program_id ==1 &&
                                <Link className="btn btn-sm history-btn btn-warning" to={"/ulchemy-feedback"} onClick={updateLocal} data-value={historydata.id}>Give FeedBack</Link>
                              }
                              { historydata.program_id ==2 &&
                                <Link className="btn btn-sm history-btn btn-warning" to={"/ulchemyelite-feedback"} onClick={updateLocal} data-value={historydata.id}>Give FeedBack</Link>
                              }
                              { historydata.program_id ==3 &&
                                <Link className="btn btn-sm history-btn btn-warning" to={"/divija-feedback"} onClick={updateLocal} data-value={historydata.id}>Give FeedBack</Link>
                              }
                              { historydata.program_id ==4 &&
                                <Link className="btn btn-sm history-btn btn-warning" to={"/kaivalya-feedback"} onClick={updateLocal} data-value={historydata.id}>Give FeedBack</Link>
                              }
                              { historydata.program_id ==5 &&
                                <Link className="btn btn-sm history-btn btn-warning" to={"/vision-feedback"} onClick={updateLocal} data-value={historydata.id}>Give FeedBack</Link>
                              }
                            </div> : ''
                          }
                          {historydata.feedback ===1 && 
                          <Link className="btn btn-sm history-btn btn-success" to={"/feedback-view/" + historydata.id}>View Feedback</Link>
                          }
                      </td>
                    </tr>
                    )) : (
                      <tr>
                        <td>No records found</td>
                      </tr>
                      )
                    }
                </tbody>
              </table>

						</div>
					</div>
				</div>
			</div>
		</section>
  );
};

export default HistoryEvent;
